import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as ProductosService from '../../services/productos.service';

import type { RootState } from '../store';
import { orderBy } from 'lodash';

type Entity = Awaited<ReturnType<typeof ProductosService.getProductos>>[number];

export const fetchProductos = createAsyncThunk<Entity[]>('productos/fetch', (_params, api) => {
  const { productos } = api.getState() as RootState;
  if (productos.entities.length) return Promise.resolve(productos.entities);
  return ProductosService.getProductos();
});

interface ProductosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: ProductosState = { loading: false, entities: [] };

const productosSlice = createSlice({
  name: 'productos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductos.fulfilled, (state, action) => {
      state.entities = orderBy(action.payload, ['descripcion'], ['asc']);
      state.loading = false;
    });

    builder.addCase(fetchProductos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProductos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const productosActions = {
  ...productosSlice.actions,
  fetchProductos
};

export default productosSlice.reducer;
