const PERMISSIONS_LIST = [
  'ENTIDADES_LIST',
  'ENTIDADES_CREATE',
  'ENTIDADES_READ',
  'ENTIDADES_UPDATE',
  'ENTIDADES_DELETE',
  'ENTIDADES_PRODUCTOS_OFRECIDOS',
  'ENTIDADES_PRODUCTOS_DEMANDADOS',
  'ENTIDADES_SECTOR_ACTIVIDAD',
  'ENTIDADES_RUBROS',
  'ENTIDADES_USUARIO',
  'ENTIDADES_TIPO_ENTIDAD',
  'ENTIDADES_LOGO',
  'ENTIDADES_SELF_READ',
  'ENTIDADES_SELF_LOGO',
  'ENTIDADES_SELF_UPDATE',
  'REUNIONES_LIST',
  'REUNIONES_SELF_LIST',
  'REUNIONES_DOWNLOAD_REUNIONES_REPORT',
  'EVENTOS_LIST',
  'EVENTOS_CREATE',
  'EVENTOS_READ',
  'EVENTOS_UPDATE',
  'EVENTOS_DELETE',
  'EVENTOS_SET_LOGO',
  'EVENTOS_LIST_INSCRIPCIONES',
  'INSCRIPCIONES_ACREDITAR',
  'INSCRIPCIONES_SELF_ACREDITAR',
  'INSCRIPCIONES_DESACREDITAR',
  'EVENTOS_CERRAR_ACREDITACION',
  'EVENTOS_DESCARGAR_REPORTE_INSCRIPCIONES',
  'EVENTOS_DESCARGAR_REPORTE_ACREDITACIONES',
  'INSCRIPCIONES_LIST',
  'INSCRIPCIONES_CREATE',
  'INSCRIPCIONES_READ',
  'INSCRIPCIONES_UPDATE',
  'INSCRIPCIONES_DELETE',
  'INSCRIPCIONES_REUNIONES_DESEADAS',
  'INSCRIPCIONES_SELF_CREATE',
  'INSCRIPCIONES_SELF_LIST',
  'INSCRIPCIONES_SELF_REUNIONES_DESEADAS',
  'MUNICIPIOS_LIST',
  'MUNICIPIOS_READ',
  'PERMISSIONS_LIST',
  'PERMISSIONS_CREATE',
  'PERMISSIONS_READ',
  'PERMISSIONS_UPDATE',
  'PERMISSIONS_DELETE',
  'PRODUCTOS_LIST',
  'PRODUCTOS_CREATE',
  'PRODUCTOS_READ',
  'PRODUCTOS_UPDATE',
  'PRODUCTOS_DELETE',
  'PROVINCIAS_LIST',
  'PROVINCIAS_READ',
  'ROLES_LIST',
  'ROLES_CREATE',
  'ROLES_READ',
  'ROLES_UPDATE',
  'ROLES_DELETE',
  'ROLES_PERMISSIONS',
  'RUBROS_LIST',
  'RUBROS_CREATE',
  'RUBROS_READ',
  'RUBROS_UPDATE',
  'RUBROS_DELETE',
  'SECTOR_ACTIVIDADES_LIST',
  'SECTOR_ACTIVIDADES_CREATE',
  'SECTOR_ACTIVIDADES_READ',
  'SECTOR_ACTIVIDADES_UPDATE',
  'SECTOR_ACTIVIDADES_DELETE',
  'TIPOS_ENTIDAD_LIST',
  'TIPOS_ENTIDAD_CREATE',
  'TIPOS_ENTIDAD_READ',
  'TIPOS_ENTIDAD_UPDATE',
  'TIPOS_ENTIDAD_DELETE',
  'TIPOS_ENTIDAD_PRODUCTOS_OFRECIDOS',
  'TIPOS_ENTIDAD_PRODUCTOS_DEMANDADOS',
  'TIPOS_ENTIDAD_SECTOR_ACTIVIDAD',
  'TIPOS_ENTIDAD_RUBROS',
  'TIPOS_ENTIDAD_USUARIO',
  'USERS_LIST',
  'USERS_CREATE',
  'USERS_READ',
  'USERS_UPDATE',
  'USERS_DELETE',
  'USERS_ROLES',
  'USERS_UPDATE_SELF',
  'USERS_UPDATE_SELF_PASSWORD',
  'COMUNICACIONES_TEMPLATE_LIST',
  'COMUNICACIONES_TEMPLATE_READ',
  'COMUNICACIONES_TEMPLATE_CREATE',
  'COMUNICACIONES_TEMPLATE_UPDATE',
  'COMUNICACIONES_TEMPLATE_DELETE',
  'COMUNICACIONES_EMAILS_LIST',
  'COMUNICACIONES_EMAILS_READ',
  'COMUNICACIONES_EMAILS_CREATE',
  'COMUNICACIONES_EMAILS_DELETE',
  'COMUNICACIONES_LIST',
  'COMUNICACIONES_READ',
  'COMUNICACIONES_CREATE',
  'COMUNICACIONES_UPDATE',
  'COMUNICACIONES_DELETE',
  'COMUNICACIONES_EMAILS_SELF_LIST',
  'COMUNICACIONES_EMAILS_MARK_AS_READ',
  'SYNC_USER',
  'SYNC_EVENTOS',
  'EVENTOS_ACREDITAR_EN_CURSO',
  'UNLOCK_REMOTE_EVENTOS',
  'EVENTOS_DESCARGAR_REPORTE_ANUAL'
] as const;

type ToObj<K extends string> = {
  [P in K]: P;
};

export type PERMISSIONS_KEYS = (typeof PERMISSIONS_LIST)[number];

export type PERMISSIONS_ENUM = ToObj<PERMISSIONS_KEYS>;

export const PERMISSIONS = PERMISSIONS_LIST.reduce((acc, elem) => {
  return {
    ...acc,
    [elem]: elem
  };
}, {} as Record<PERMISSIONS_KEYS, PERMISSIONS_KEYS>);
