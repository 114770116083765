/* eslint-disable react/no-unescaped-entities */
import { FC, memo } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Result, Row } from 'antd';

// Utils
import { getEventoUrlId } from '../../../../../../utils/eventoId';

// // Images
// import confirmationIcon from '../../../../../../assets/images/confirmation.png';

// Constants
import { ROUTES } from '../../../../../../constants/routes-constants';

// Interfaces
import type { Evento } from '../../../../../../interfaces/evento';

interface InscriptionConfirmationModalProps {
  open: boolean;
  evento: Evento;
  onClose: () => void;
}

const ButtonStyles = { width: '100%', borderRadius: 15 } as const;

export const InscriptionConfirmationModal: FC<InscriptionConfirmationModalProps> = memo((props) => {
  const { open, onClose, evento } = props;

  return (
    <Modal closable={false} width={600} open={open} centered footer={false} onClose={onClose}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Result
            status="success"
            title="Inscripción confirmada"
            subTitle={
              <div style={{ marginTop: 20 }}>
                Tu inscripción al evento "{evento.nombre}" fue registrada correctamente.
                <div style={{ marginTop: 20, marginBottom: -20 }}>
                  Deberías haber recibido un correo de confirmación con los detalles de tu
                  inscripción. Te enviaremos otro correo más adelante con los siguientes pasos a
                  seguir.
                </div>
              </div>
            }
          />
        </Col>
        {/* <Col span={24}>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <img style={{ height: 100 }} src={confirmationIcon} />
          </div>
        </Col>

        <Col span={24}>
          <div style={{ textAlign: 'center', marginBottom: 25 }}>
            <h2>Inscripción confirmada</h2>

            <h4>Tu inscripción al evento "{evento.nombre}" fue registrada correctamente.</h4>

            <h4>
              Deberías haber recibido un correo de confirmación con los detalles de tu inscripción.
              Te enviaremos otro correo más adelante con los siguientes pasos a seguir.
            </h4>
          </div>
        </Col> */}

        <Col span={24}>
          <Link to={ROUTES.EVENT_DETAILS_ROUTE.replace(':id', getEventoUrlId(evento))}>
            <Button
              shape="round"
              type="primary"
              style={ButtonStyles}
              onClick={() => {
                onClose();
              }}
            >
              Cerrar
            </Button>
          </Link>
        </Col>
      </Row>
    </Modal>
  );
});

InscriptionConfirmationModal.displayName = 'InscriptionConfirmationModal';

export default InscriptionConfirmationModal;
