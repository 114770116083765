import { FC } from 'react';

// Utils
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

const tagStyle: React.CSSProperties = {
  padding: 5,
  fontSize: 13,
  color: 'black',
  paddingLeft: 10,
  // borderRadius: 50,
  paddingRight: 10,
  fontWeight: 'bold',
  // border: '1px solid',
  display: 'inline-block'
  // borderColor: PRIMARY_COLOR,
  // backgroundColor: PRIMARY_COLOR
} as const;

interface CountDownTimeTagProps {
  action: string;
  endDate: string;
  startDate: string;
}

export const CountDownTimeTag: FC<CountDownTimeTagProps> = (props) => {
  const { startDate, endDate, action } = props;
  const toOpen = dayjs().isBefore(dayjs(startDate));

  if (toOpen) {
    const days = dayjs(startDate).endOf('day').diff(dayjs().startOf('day'), 'days');

    if (days === 0) {
      return <span style={tagStyle}>Hoy abre {action}</span>;
    }

    return (
      <span style={tagStyle}>
        Faltan {days === 1 ? '1 día' : `${days} días`} para que comience {action}
      </span>
    );
  } else {
    const isClosed = dayjs().isAfter(dayjs(endDate));

    if (isClosed) return null;

    // const days = dayjs(endDate).diff(dayjs(), 'days');
    const sameDay = dayjs(endDate).isSame(dayjs(), 'day');

    if (sameDay) {
      return <span style={tagStyle}>Hoy cierra {action}</span>;
    }

    return <span style={tagStyle}>{capitalize(action)} cierra dentro de: </span>;
  }
};
