import { memo, useEffect } from 'react';
import StatusComponent from './StatusComponent';
import { PAGE_TITLE } from '../../constants/page';

const EventoNoDisponible: React.FC = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | Evento no disponible`;
  }, []);

  return (
    <StatusComponent
      status="warning"
      title="El evento seleccionado no está disponible en este momento."
    />
  );
});

EventoNoDisponible.displayName = 'EventoNoDisponible';

export default EventoNoDisponible;
