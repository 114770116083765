import { memo, useEffect } from 'react';

// Constants
import { PAGE_TITLE } from '../../../constants/page';
import { Card, Col, Row } from 'antd';

export const AboutPage = memo(() => {
  useEffect(() => {
    document.title = `${PAGE_TITLE} | ¿Cómo funciona una Ronda de Negocios?`;
  }, []);

  return (
    <Row gutter={[16, 16]} align={'middle'} justify={'center'} style={{ padding: 16 }}>
      <Col span={24} lg={18} xxl={16}>
        <Card>
          <div>
            <h1>¿Cómo funciona una Ronda de Negocios de la provincia de Buenos Aires?</h1>
            <h2>PASOS PARA PARTICIPAR</h2>

            <h3>INSCRIPCIÓN</h3>
            <p>
              El primer paso es inscribir a tu empresa como participante de la Ronda de Negocios.
              Para eso, tenés que registrarte en el sistema y completar los datos requeridos.
            </p>
            <p>
              Allí vas a tener que brindar una breve descripción de tu empresa, describir qué tipo
              de productos o servicios tenés para ofrecer y cuáles demandás, y contarnos con qué
              rubros de actividad te interesa vincularte.
            </p>
            <p>
              Una vez que registres tu empresa, vas a poder inscribirte a los eventos disponibles.
            </p>
            <p>→ ¡Tené en cuenta las fechas límite de inscripción para cada evento!</p>
            <p>
              La inscripción y participación en las Rondas de Negocios de la provincia de Buenos
              Aires no conlleva ningún costo para las empresas.
            </p>

            <h3>SELECCIÓN</h3>
            <p>
              Una vez finalizado el período de inscripción, te va a llegar un correo electrónico
              avisando que comienza el período de selección de contrapartes, y un enlace a la
              plataforma para que ingreses a elegir con quiénes querés entrevistarte. Es importante
              que lo hagas antes de que termine el período de selección.
            </p>
            <p>
              → Este paso es fundamental, ya que con las selecciones de todas las empresas, el día
              de la Ronda de Negocios se podrán hacer los cruces para que puedas reunirte tanto con
              las que elegiste como con las que te eligieron como contraparte.
            </p>

            <h3>JORNADA DE LA RONDA DE NEGOCIOS</h3>
            <p>
              Cuando llegue el día del evento, es muy importante que te presentes en el lugar y
              horario indicados. La presencialidad es fundamental para garantizar que se puedan
              concretar las entrevistas seleccionadas por cada empresa participante.
            </p>
            <p>
              → Los cruces para organizar las agendas de entrevistas se realizan con un programa
              informático en el lugar del evento. Si llegás luego del cierre de dicho programa, tu
              empresa no va a figurar en los listados, y no será posible organizar tus entrevistas,
              por eso se solicita puntualidad.
            </p>

            <h3>ACREDITACIÓN</h3>
            <p>
              Cuando llegues al lugar del evento, el primer paso es acreditarte. En la mesa de
              acreditación vamos a registrar tu presencia y entregarte una credencial
              identificatoria. Una vez cerrado el horario de acreditación, avisaremos por locución
              para hacer entrega de las agendas de reuniones.
            </p>
            <p>
              → Hasta entonces, te recomendamos visitar los stands de los organismos presentes,
              donde podrás conocer diversas líneas de asistencia técnica, comercial y financiera con
              que cuenta el Gobierno de la Provincia. También podés compartir tus tarjetas,
              folletería o merchandising de tu empresa en las mesas dispuestas para ello.
            </p>

            <h3>AGENDAS</h3>
            <p>
              Una vez finalizado el tiempo de acreditación, se realiza el cruce de empresas
              -incluyendo únicamente a las que se hayan registrado y acreditado- para obtener las
              agendas de cada participante. Te entregaremos una hoja impresa con tu agenda de
              entrevistas para la jornada, indicando con qué empresas te reunirás y en qué mesas. En
              todo el salón, vas a ver que contamos con mesas numeradas.
            </p>
            <p>→ Recordá que es muy importante que respetes los horarios y las mesas asignadas.</p>

            <h3>ENTREVISTAS</h3>
            <p>
              Ahora será el momento de las reuniones. Cada entrevista dura aproximadamente 10
              minutos, y solamente vas a reunirte con las empresas que concurran presencialmente.
            </p>

            <h3>Algunas consideraciones importantes:</h3>

            <h4>PRESENCIALIDAD</h4>
            <p>
              Las Rondas de Negocios son una herramienta gratuita que el Gobierno de la Provincia de
              Buenos Aires pone a disposición de las empresas. Para su correcto funcionamiento, es
              fundamental que quienes se inscriben, asistan el día de la convocatoria. Te pedimos
              por favor que sólo te inscribas a un evento si tenés la seguridad de poder asistir en
              el día y horario del mismo.
            </p>

            <h4>NORMAS DE CONVIVENCIA</h4>
            <p>
              Inscribir a una empresa en una Ronda de Negocios implica el compromiso de cumplimiento
              de las reglas de funcionamiento de los eventos.
            </p>
            <p>
              El Ministerio de Producción, Ciencia e Innovación Tecnológica se reserva el derecho de
              admisión y permanencia en las Rondas de Negocios, conforme los lineamientos de la Ley
              Nacional N° 26.370.
            </p>
            <p>
              Las Rondas de Negocios organizadas por la Provincia de Buenos Aires son un espacio
              seguro y libre de violencias. Se prioriza el buen trato entre participantes,
              organizadores, y demás asistentes. En caso de producirse cualquier comportamiento que
              conlleve faltas de respeto, de cordialidad, violencia, o que se encuentre por fuera de
              las normas de convivencia necesarias para un correcto desempeño de la actividad, se
              aplicará el derecho de admisión y/o permanencia que ejerce el organismo organizador.
            </p>

            <h4>DIFUSIÓN DE DATOS</h4>
            <p>
              Inscribir a una empresa en una Ronda de Negocios implica aceptar que se compartan los
              datos de contacto de la misma con sus contrapartes durante las entrevistas. Asimismo,
              si una empresa se inscribe y no asiste a la Ronda de Negocios, el Ministerio podrá
              compartir sus datos de contacto con las empresas que la hubieran seleccionado como
              contraparte.
            </p>
          </div>
        </Card>
      </Col>
    </Row>
  );
});

AboutPage.displayName = 'AboutPage';
