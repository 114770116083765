import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Services
import * as EntidadService from '../../services/entidad.service';

// Utils
import { isActionableForUser } from '../../utils/validateRole';

// Constants
import { PERMISSIONS } from '../../constants/permissions';

// Interfaces
import type { RootState } from '../store';
import type { EntidadDetallada } from '../../interfaces/entidad';

export const fetchEntidad = createAsyncThunk<EntidadDetallada, number>(
  'entidad/fetchEntidadStatus',
  async (id, { rejectWithValue, getState }) => {
    const {
      auth: { user }
    } = getState() as RootState;

    const request =
      user && isActionableForUser(user, [PERMISSIONS.ENTIDADES_READ])
        ? EntidadService.getEntidad
        : EntidadService.getMyEntidad;

    try {
      return request(id as number);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

interface EntidadState {
  loading: boolean;
  entidad: EntidadDetallada | null;
  dirty: boolean;
}

const initialState: EntidadState = { loading: false, entidad: null, dirty: false };

const EntidadSlice = createSlice({
  name: 'Entidad',
  initialState,
  reducers: {
    setDirty(state, action: PayloadAction<boolean>) {
      state.dirty = action.payload;
    },

    setEntidad(state, action: PayloadAction<EntidadDetallada | null>) {
      state.entidad = action.payload;
      state.dirty = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntidad.fulfilled, (state, action) => {
      state.entidad = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEntidad.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEntidad.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const EntidadActions = {
  ...EntidadSlice.actions,
  fetchEntidad
};

export default EntidadSlice.reducer;
