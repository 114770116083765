import { FC, memo, ReactNode } from 'react';

import './style.scss';

interface BackgroundBannerProps {
  title?: string;
  fontSize?: number;
  button?: ReactNode;
  description: string[];
}

export const BackgroundBanner: FC<BackgroundBannerProps> = memo((props) => {
  const { description, title, fontSize = 20 } = props;

  return (
    <div className="home-banner">
      <div className="home-banner-opacity-layer"></div>
      <div className="home-banner__content">
        {title && <h1>{title}</h1>}
        {description.map((desc, index) => (
          <p key={index} className="home-banner__description" style={{ fontSize }}>
            {desc}
          </p>
        ))}
        {props.button}
      </div>
    </div>
  );
});

BackgroundBanner.displayName = 'BackgroundBanner';
