import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Services
import * as EventoService from '../../services/eventos.service';

// Utils
import { isActionableForUser } from '../../utils/validateRole';

// Constants
import { PERMISSIONS } from '../../constants/permissions';

// Interfaces
import type { RootState } from '../store';
import type { Reunion } from '../../interfaces/reunion';
import type { Inscripcion } from '../../interfaces/inscripcion';
import type { Evento, PublicEventRead } from '../../interfaces/evento';

export const fetchEvento = createAsyncThunk<Evento, number>(
  'evento/fetchEventoStatus',
  // @ts-ignore
  async (id, { rejectWithValue, getState }) => {
    const {
      auth: { user }
    } = getState() as RootState;

    const request =
      !user || !isActionableForUser(user, [PERMISSIONS.EVENTOS_READ])
        ? EventoService.getPublicEventById
        : EventoService.getEventoById;

    try {
      return request(id as number);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

export const fetchReuniones = createAsyncThunk<any[], number>(
  'evento/fetchEventosStatus',
  async (id, { rejectWithValue }) => {
    const promises = [EventoService.getEventTurnos(id), EventoService.getEventReuniones(id)];

    try {
      const [turnosResponse, reunionesResponse] = await Promise.all(promises);

      const turnos = turnosResponse as string[];
      const reuniones = reunionesResponse as Reunion[];

      return [turnos, reuniones];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

interface EventoState {
  loading: boolean;
  reunionesLoading: boolean;
  scheduleTurnsDrawerIsOpen: boolean;
  evento: Evento | PublicEventRead | null;

  editInscripcionDrawer: Inscripcion | null;
  participantsModalIsOpen: boolean;

  dirty: boolean;

  turnos: string[];
  reuniones: Reunion[];
}

const initialState: EventoState = {
  evento: null,
  loading: false,
  scheduleTurnsDrawerIsOpen: false,

  editInscripcionDrawer: null,
  participantsModalIsOpen: false,

  dirty: false,

  turnos: [],
  reuniones: [],
  reunionesLoading: true
};

const EventoSlice = createSlice({
  name: 'Evento',
  initialState,
  reducers: {
    setEvento: (state, action) => {
      state.evento = action.payload;
      state.dirty = false;
    },

    setDirty: (state, action: PayloadAction<boolean>) => {
      state.dirty = action.payload;
    },

    setParticipantsModalStatus: (state, action: PayloadAction<boolean>) => {
      state.participantsModalIsOpen = action.payload;
    },

    setInscripcionDrawer: (state, action: PayloadAction<Inscripcion | null>) => {
      state.editInscripcionDrawer = action.payload;
    },

    setScheduleTurnsDrawerStatus: (state, action: PayloadAction<boolean>) => {
      state.scheduleTurnsDrawerIsOpen = action.payload;
    },

    setTurns: (state, action: PayloadAction<string[]>) => {
      state.turnos = action.payload;
    },

    setReuniones: (state, action: PayloadAction<Reunion[]>) => {
      state.reuniones = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvento.fulfilled, (state, action) => {
      state.evento = action.payload;
      state.dirty = false;
      state.loading = false;
    });

    builder.addCase(fetchEvento.pending, (state) => {
      state.loading = true;
      state.scheduleTurnsDrawerIsOpen = false;
    });

    builder.addCase(fetchEvento.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchReuniones.pending, (state) => {
      state.reunionesLoading = true;
    });

    builder.addCase(fetchReuniones.rejected, (state) => {
      state.reunionesLoading = false;
    });

    builder.addCase(fetchReuniones.fulfilled, (state, action) => {
      state.reunionesLoading = false;
      state.turnos = action.payload[0];
      state.reuniones = action.payload[1];
    });
  }
});

export const EventoActions = {
  ...EventoSlice.actions,
  fetchEvento,
  fetchReuniones
};

export default EventoSlice.reducer;
