import { Button, Col, Row } from 'antd';
import { memo } from 'react';

import './style.scss';
import WhiteBanner from '../../../../../components/banner/whiteBanner';

// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes-constants';

export const PrevEvents = memo(() => {
  return (
    <div>
      <Row>
        <Col span={24} style={{ background: 'white' }}>
          <WhiteBanner
            borderBottom
            borderTop
            subtitle="Conocé también nuestros"
            title="Eventos realizados"
            action={
              <Link to={ROUTES.PREVIOUS_EVENTS_ROUTE}>
                <Button shape="round" type="primary" size="large">
                  Historial de eventos
                </Button>
              </Link>
            }
          />
        </Col>
      </Row>
    </div>
  );
});

PrevEvents.displayName = 'NextEvents';

export default PrevEvents;
