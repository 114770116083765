import { memo } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BackgroundBanner } from '../../../../../components/banner/backgroundBanner';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

export const HomeBanner2 = memo(() => {
  const navigate = useNavigate();

  return (
    <BackgroundBanner
      fontSize={17}
      description={[]}
      button={
        <Button shape="round" size="large" onClick={() => navigate(ROUTES.ABOUT_ROUTE)}>
          ¿Cómo funciona una Ronda de Negocios?
        </Button>
      }
    />
  );
});

HomeBanner2.displayName = 'HomeBanner2';
