import { Evento, PublicEventList, PublicEventRead } from '../interfaces/evento';

export const getEventNameFromUrl = (eventoUrlId: string) => {
  return eventoUrlId.split('_').slice(0, -1).join('_');
};

export const hasValidEventName = (
  eventoUrlId: string,
  evento: Evento | PublicEventRead | PublicEventList
) => {
  return eventoUrlId === getEventoUrlId(evento);
};

export const getEventoUrlId = (evento: Evento | PublicEventRead | PublicEventList) => {
  return `${evento.nombre
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '')
    .split(' ')
    .join('_')}_${evento.id}`;
};

export const getEventoIdFromUrl = (eventoUrlId: string) => {
  return eventoUrlId.split('_').reverse()[0];
};
