import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as ProvinciasService from '../../services/provincia.service';

import type { RootState } from '../store';
import { orderBy } from 'lodash';

type Entity = Awaited<ReturnType<typeof ProvinciasService.getProvincias>>[number];

export const fetchProvincias = createAsyncThunk<Entity[]>('provincias/fetch', (_params, api) => {
  const { provincias } = api.getState() as RootState;
  if (provincias.entities.length) return Promise.resolve(provincias.entities);
  return ProvinciasService.getProvincias();
});

interface ProvinciasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: ProvinciasState = { loading: false, entities: [] };

const provinciasSlice = createSlice({
  name: 'provincias',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvincias.fulfilled, (state, action) => {
      state.entities = orderBy(action.payload, ['descripcion'], ['asc']);
      state.loading = false;
    });

    builder.addCase(fetchProvincias.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProvincias.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const provinciasActions = {
  ...provinciasSlice.actions,
  fetchProvincias
};

export default provinciasSlice.reducer;
