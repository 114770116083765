import { memo, useCallback, useMemo } from 'react';

// Components
import { Button, ButtonProps } from 'antd';

// Router
import { useNavigate } from 'react-router-dom';

// Utils
import dayjs from 'dayjs';
import { getEventoUrlId } from '../../../../../../utils/eventoId';
import { isActionableForUser } from '../../../../../../utils/validateRole';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { pendingRegistrationModalActions } from '../../../../../../store/slices/pendingRegistrationModal';

// Constants
import { PRIMARY_COLOR } from '../../../../../../constants/colors';
import { ROUTES } from '../../../../../../constants/routes-constants';
import { PERMISSIONS } from '../../../../../../constants/permissions';

// Interface
import type { FC } from 'react';
import type { PublicEventRead } from '../../../../../../interfaces/evento';

interface SubscribeCardProps {
  isAcredited: boolean;
  isInscripted: boolean;
  evento: PublicEventRead;
}

const CustomButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      ghost
      shape="round"
      type="primary"
      style={{ width: '100%', height: 40, marginBottom: -20 }}
      {...props}
    />
  );
};

const ClosedInscriptions: FC<{ isInscripted: boolean }> = memo(() => (
  <>
    <CustomButton disabled style={{ width: '100%', height: 40, marginBottom: -10, marginTop: -30 }}>
      Inscripciones cerradas
    </CustomButton>
  </>
));

const tagStyle = {
  padding: 11,
  fontSize: 13,
  marginTop: 20,
  color: 'white',
  borderRadius: 50,
  textAlign: 'center',
  border: '1px solid',
  borderColor: PRIMARY_COLOR,
  backgroundColor: PRIMARY_COLOR
} as const;

const AcreditedButton: FC<{ evento: PublicEventRead }> = memo((props) => {
  const navigate = useNavigate();

  const { evento } = props;
  if (!evento.acreditacion_fin)
    <>
      <CustomButton disabled={!evento.acreditacion_fin}>Acreditado en el evento</CustomButton>
      <div style={tagStyle}>A la brevedad estará disponible tu agenda de reuniones</div>
    </>;

  return (
    <>
      <CustomButton
        type="primary"
        ghost={false}
        onClick={() => {
          navigate(ROUTES.EVENT_SCHEDULE_ROUTE.replace(':id', getEventoUrlId(evento)));
        }}
      >
        Ver mi cronograma
      </CustomButton>
    </>
  );
});

AcreditedButton.displayName = 'AcreditedButton';

const InscriptedButton: FC<{ isInscripted: boolean; evento: PublicEventRead }> = memo((props) => {
  const { evento } = props;
  return (
    <>
      <CustomButton disabled>Inscripción confirmada</CustomButton>

      {!evento.tiene_seleccion ? null : evento.seleccion_inicio && evento.seleccion_fin ? null : (
        <div style={tagStyle}>
          En breve las personas inscriptas recibirán un email con un link para seleccionar con qué
          entidades quieren reunirse el día de la ronda.
        </div>
      )}
    </>
  );
});

InscriptedButton.displayName = 'InscriptedButton';

const InscriptionButton: FC<{ disabled: boolean; evento: PublicEventRead }> = memo((props) => {
  const { disabled, evento } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const canRegister = useMemo(() => {
    return (
      isActionableForUser(user!, [PERMISSIONS.INSCRIPCIONES_SELF_CREATE]) &&
      user?.roles.some((role) => role.descripcion === 'EMPRESA')
    );
  }, [user]);

  const onClick = useCallback(() => {
    if (disabled) return;
    if (!user) return navigate(ROUTES.LOGIN_ROUTE);

    if (!user.entidad)
      return dispatch(pendingRegistrationModalActions.setPendingRegistrationModal(true));
    navigate(ROUTES.EVENT_INSCRIPTION_ROUTE.replace(':id', getEventoUrlId(evento)));
  }, [user, disabled, evento, navigate, dispatch]);

  return (
    <Button
      ghost
      type="primary"
      onClick={onClick}
      disabled={disabled || !canRegister}
      style={{ width: '100%', height: 40 }}
    >
      Inscribirse en el evento
    </Button>
  );
});

const SelectionButton: FC<{ evento: PublicEventRead }> = memo((props) => {
  const { evento } = props;

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(ROUTES.EVENT_SELECTION_ROUTE.replace(':id', getEventoUrlId(evento)));
  }, [navigate, evento]);

  return (
    <CustomButton onClick={onClick} style={{ width: '100%', height: 40 }}>
      Ir a seleccionar entidades
    </CustomButton>
  );
});

SelectionButton.displayName = 'SelectionButton';

export const EventButton: FC<SubscribeCardProps> = memo((props) => {
  const { evento } = props;

  const pendingOpening = useMemo(
    () => dayjs().isBefore(dayjs(evento.inscripcion_inicio)),
    [evento.inscripcion_inicio]
  );

  const inscriptionClosed = useMemo(
    () => dayjs().isAfter(dayjs(evento.inscripcion_fin)),
    [evento.inscripcion_fin]
  );

  const selectionAvailable = useMemo(
    () =>
      evento.tiene_seleccion && evento.seleccion_inicio && evento.seleccion_fin
        ? dayjs().isAfter(dayjs(evento.seleccion_inicio)) &&
          dayjs().isBefore(dayjs(evento.seleccion_fin))
        : false,
    [evento.tiene_seleccion, evento.seleccion_inicio, evento.seleccion_fin]
  );

  if (props.isAcredited) {
    return <AcreditedButton evento={evento} />;
  }

  if (props.isInscripted) {
    if (selectionAvailable) return <SelectionButton evento={evento} />;
    return <InscriptedButton evento={evento} isInscripted={props.isInscripted} />;
  }

  if (inscriptionClosed) {
    return <ClosedInscriptions isInscripted={props.isInscripted} />;
  }

  // Si la inscripción no cerró muestro el botón de inscripción
  return <InscriptionButton evento={evento} disabled={pendingOpening} />;
});

EventButton.displayName = 'EventButton';
InscriptionButton.displayName = 'InscriptionButton';
ClosedInscriptions.displayName = 'ClosedInscriptions';
