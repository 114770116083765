export const ROUTES = {
  HOMEPAGE_ROUTE: '/',
  ABOUT_ROUTE: '/como-funcionan',
  EVENT_DETAILS_ROUTE: '/evento/:id',
  EVENT_DETAILS_V2_ROUTE: '/evento-v2/:id',
  PREVIOUS_EVENTS_ROUTE: '/eventos-realizados',
  EVENT_INSCRIPTION_ROUTE: '/evento/:id/inscripcion',

  // Auth
  LOGIN_ROUTE: '/ingresar',
  PROFILE_ROUTE: '/perfil',
  REGISTER_ROUTE: '/registrarse',
  VALIDATE_EMAIL_ROUTE: '/validar-email',
  FORGOT_PASSWORD_ROUTE: '/olvide-contraseña',
  RESET_PASSWORD_ROUTE: '/recuperar-contraseña',

  // Entity
  ENTITIES_LIST_ROUTE: '/gestion/entidades',
  REGISTER_ENTITY_ROUTE: '/registrar-entidad',
  EDIT_ENTITY_ROUTE: '/gestion/entidades/editar/:id',
  ADMIN_NEW_ENTITY_ROUTE: '/gestion/entidad-nueva',

  // Events
  EVENTS_LIST_ROUTE: '/gestion/eventos',
  NEW_EVENT_ROUTE: '/gestion/evento-nuevo',
  EDIT_EVENT_ROUTE: '/gestion/eventos/:id',
  EVENT_SELECTION_ROUTE: '/evento/:id/seleccion',
  EVENT_SCHEDULE_ROUTE: '/evento/:id/cronograma',
  EDIT_EVENT_SELECTION_ROUTE: '/gestion/eventos/:id/seleccion/:inscripcion_id',

  // Comunications
  COMUNICATIONS_TEMPLATES_ROUTE: '/comunicaciones/plantillas',
  COMUNICATIONS_NEW_TEMPLATE_ROUTE: '/comunicaciones/nueva-plantilla',
  COMUNICATIONS_EDIT_TEMPLATE_ROUTE: '/comunicaciones/plantillas/editar/:id',
  COMUNICATIONS_LIST_ROUTE: '/comunicaciones/listado',
  COMUNICATIONS_NEW_ROUTE: '/comunicaciones/nueva',
  COMUNICATIONS_EDIT_ROUTE: '/comunicaciones/:id',

  PAGE_404_ROUTE: '/404'
};
