import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as TipoEntidadService from '../../services/tipoEntidad.service';

import type { RootState } from '../store';
import { orderBy } from 'lodash';

type Entity = Awaited<ReturnType<typeof TipoEntidadService.getTiposEntidades>>[number];

export const fetchTiposEntidades = createAsyncThunk<Entity[]>(
  'tiposEntidades/fetch',
  (_params, api) => {
    const { tiposEntidades } = api.getState() as RootState;
    if (tiposEntidades.entities.length) return Promise.resolve(tiposEntidades.entities);
    return TipoEntidadService.getTiposEntidades();
  }
);

interface TiposEntidadesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposEntidadesState = { loading: false, entities: [] };

const tiposEntidadesSlice = createSlice({
  name: 'tiposEntidades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposEntidades.fulfilled, (state, action) => {
      state.entities = orderBy(action.payload, ['descripcion'], ['asc']);
      state.loading = false;
    });

    builder.addCase(fetchTiposEntidades.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposEntidades.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposEntidadesActions = {
  ...tiposEntidadesSlice.actions,
  fetchTiposEntidades
};

export default tiposEntidadesSlice.reducer;
