import React from 'react';

// Theme
import { ConfigProvider, message, ThemeConfig } from 'antd';
import { PRIMARY_COLOR } from './constants/colors';

// Components
import RootComponent from './RootComponent';

// Redux
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

// Locale
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import es from 'antd/locale/es_ES';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AddCompanyModal from './components/modals/addCompanyModal';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Argentina/Buenos_Aires');

dayjs.locale('es');

dayjs.extend(customParseFormat);

const borderRadius = 15 as const;
const inputBorderRadius = 12 as const;

message.config({
  duration: 5
});

const theme: ThemeConfig = {
  components: {
    Input: {
      borderRadius: inputBorderRadius
    },
    InputNumber: {
      borderRadius: inputBorderRadius
    },
    Select: {
      borderRadius: inputBorderRadius
    },
    DatePicker: {
      borderRadius: inputBorderRadius
    },
    Button: {
      borderRadius
    }
  },
  token: {
    fontFamily: 'Encode Sans',
    colorPrimary: PRIMARY_COLOR
  }
} as const;

const App: React.FC = () => {
  return (
    <ConfigProvider locale={es} theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AddCompanyModal
            title="Gracias por registrarte"
            subtitle="Antes de continuar, es necesario que registres la información de tu empresa."
          />

          <RootComponent />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
