import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TipoTemplate } from '../../interfaces/comunicationTemplate';

const initialState: {
  descripcion: string;
  eventoId: number | null;
  tipo: TipoTemplate | null;
  values: Record<string, any>;
  scheduleDate: null | string;
  entidadesIds: (number | string)[];
} = {
  values: {},
  entidadesIds: [],
  scheduleDate: null,
  tipo: null,
  eventoId: null,
  descripcion: ''
};

const newComunicationSlice = createSlice({
  name: 'newComunication',
  initialState,
  reducers: {
    reset(state) {
      state.descripcion = '';
      state.eventoId = null;
      state.tipo = null;
      state.values = {};
      state.scheduleDate = null;
      state.entidadesIds = [];
    },
    setStepOneData(
      state,
      action: PayloadAction<{ descripcion: string; eventoId: number; tipo: TipoTemplate }>
    ) {
      state.descripcion = action.payload.descripcion;
      if (action.payload.eventoId) {
        if (typeof action.payload.eventoId === 'object') {
          // @ts-ignore
          state.eventoId = action.payload.eventoId.value;
        } else {
          state.eventoId = action.payload.eventoId;
        }
      }
      state.eventoId = action.payload.eventoId;
      state.tipo = action.payload.tipo;
    },
    setScheduleDate(state, action: PayloadAction<string | null>) {
      state.scheduleDate = action.payload;
    },
    setValues(state, action: PayloadAction<Record<string, any>>) {
      state.values = action.payload;
    },
    setEntidadesIds(state, action: PayloadAction<number[]>) {
      state.entidadesIds = action.payload;
    },
    addEntidadIds(state, action: PayloadAction<number[]>) {
      state.entidadesIds = [...state.entidadesIds, ...action.payload];
    },
    removeEntidadId(state, action: PayloadAction<number>) {
      state.entidadesIds = state.entidadesIds.filter((id) => id !== action.payload);
    }
  }
});

export const newComunicationAction = newComunicationSlice.actions;

export default newComunicationSlice.reducer;
