import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as SectorActividadService from '../../services/sectorActividad.service';

import type { RootState } from '../store';
import { orderBy } from 'lodash';

type Entity = Awaited<ReturnType<typeof SectorActividadService.getSectoresActividad>>[number];

export const fetchSectoresActividad = createAsyncThunk<Entity[]>(
  'sectoresActividad/fetch',
  (_params, api) => {
    const { sectoresActividad } = api.getState() as RootState;
    if (sectoresActividad.entities.length) return Promise.resolve(sectoresActividad.entities);
    return SectorActividadService.getSectoresActividad();
  }
);

interface TiposCooperativasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposCooperativasState = { loading: false, entities: [] };

const sectoresActividadSlice = createSlice({
  name: 'sectoresActividad',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSectoresActividad.fulfilled, (state, action) => {
      state.entities = orderBy(action.payload, ['descripcion'], ['asc']);
      state.loading = false;
    });

    builder.addCase(fetchSectoresActividad.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSectoresActividad.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const sectoresActividadActions = {
  ...sectoresActividadSlice.actions,
  fetchSectoresActividad
};

export default sectoresActividadSlice.reducer;
