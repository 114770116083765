/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, Spin } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';

// Utils
import dayjs from 'dayjs';
import { isActionableForUser } from '../../../../utils/validateRole';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getEventoIdFromUrl, hasValidEventName } from '../../../../utils/eventoId';
import AcreditationConfirmationModal from '../../../../components/event/acreditationConfirmationModal';

// Components
import EventDetailsContainer from './container';
import Loader from '../../../../components/layout/loader';
import ParticipantsConfirmationModal from '../../../../components/event/participantsConfirmationModal';

// Redux
import { EventoActions } from '../../../../store/slices/evento';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../store/store';
import { acreditationModalActions } from '../../../../store/slices/acreditationModal';

// Constants
import { PAGE_TITLE } from '../../../../constants/page';
import { ROUTES } from '../../../../constants/routes-constants';
import { PERMISSIONS } from '../../../../constants/permissions';

// Services
import EventoService from '../../../../services/eventos.service';
import { InscripcionService } from '../../../../services/inscripciones.service';

// Interfaces
import type { HookAPI } from 'antd/es/modal/useModal';
import type { PublicEventRead } from '../../../../interfaces/evento';

const requestsAcreditation = (
  evento: PublicEventRead,
  modal: HookAPI,
  acreditacionesPendientes: number,
  openMultipleParticipantsModal: () => void,
  dispatch: AppDispatch
) => {
  const { acreditacion_inicio, acreditacion_fin } = evento;

  const now = dayjs();
  const fin = dayjs(acreditacion_fin);
  const inicio = dayjs(acreditacion_inicio);

  if (now.isBefore(inicio) || now.isAfter(fin)) {
    modal.info({
      centered: true,
      title: `La acreditación para el evento "${evento.nombre}" no está disponible`,
      content:
        now < inicio
          ? `Parece que el período de acreditación no ha empezado. Si crees que es un error, por favor acercate al stand para que puedan ayudarte.`
          : `La acreditación para el evento ya ha terminado. Si crees que es un error, acercate al stand para que puedan ayudarte.`
    });
    return Promise.resolve();
  }

  if (acreditacionesPendientes > 1) {
    openMultipleParticipantsModal();
    return Promise.resolve();
  }

  dispatch(acreditationModalActions.doAcreditation({ cantidad: 1, eventoId: evento.id }));
  return Promise.resolve();
};

export const EventDetailsPage = memo(() => {
  const params = useParams<{ id: string }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, context] = Modal.useModal();
  const [searchParams, setSeachParams] = useSearchParams();
  const hasAcreditationRequest = !!searchParams.get('acreditacion_request');

  const user = useAppSelector((state) => state.auth.user);

  const [loading, setLoading] = useState<boolean>(true);
  const [evento, setEvento] = useState<PublicEventRead | null>(null);

  const [acreditedAmount, setAcreditedAmount] = useState<number>(0);
  const [inscriptedAmount, setInscriptedAmount] = useState<number>(0);

  const onOpenParticipantsModal = useCallback(() => {
    dispatch(EventoActions.setParticipantsModalStatus(true));
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    const request = !isActionableForUser(user!, [PERMISSIONS.EVENTOS_READ])
      ? EventoService.getPublicEventById
      : EventoService.getEventoById;

    request(Number(getEventoIdFromUrl(params.id!)))
      .then((response) => {
        setEvento(response as PublicEventRead);
        document.title = `${PAGE_TITLE} | ${response.nombre}`;
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [params.id, user]);

  useEffect(() => {
    if (evento && user) {
      InscripcionService.selfEventStatus(evento.id).then((response) => {
        const { acreditedAmount, inscriptedAmount } = response;
        setAcreditedAmount(acreditedAmount);
        setInscriptedAmount(inscriptedAmount);

        if (hasAcreditationRequest) {
          requestsAcreditation(
            evento,
            modal,
            inscriptedAmount - acreditedAmount,
            onOpenParticipantsModal,
            dispatch
          ).finally(() => {
            setSeachParams({});
          });
        }
      });
    }

    if (evento && !hasValidEventName(params.id!, evento)) {
      navigate(ROUTES.PAGE_404_ROUTE);
    }

    if (evento && !user && hasAcreditationRequest) {
      navigate(`${ROUTES.LOGIN_ROUTE}?eventRedirect=${evento.id}&acreditacion_request=true`);
    }
  }, [
    user,
    modal,
    evento,
    dispatch,
    navigate,
    setSeachParams,
    hasAcreditationRequest,
    onOpenParticipantsModal,
    params.id
  ]);

  if (loading || !evento) return <Loader />;

  return (
    <>
      {context}
      <ParticipantsConfirmationModal
        evento={evento!}
        acreditacionesPendientes={inscriptedAmount - acreditedAmount}
      />
      <AcreditationConfirmationModal evento={evento!} />
      <EventDetailsContainer
        evento={evento!}
        setEvento={setEvento}
        isAcredited={acreditedAmount > 0}
        isInscripted={inscriptedAmount > 0}
      />
    </>
  );
});

EventDetailsPage.displayName = 'EventDetailsPage';

export default EventDetailsPage;
