import { Button } from 'antd';
import ErrorModal from '../../../../../components/modals/errorModal';
import PreviouslyRegisteredModal from './modals/previouslyregistered';
import InscriptionConfirmationModal from './modals/confirmationModal';
import ProForm from '@ant-design/pro-form';

// Interfaces
import { useState, type FC } from 'react';
import type { Evento } from '../../../../../interfaces/evento';
import type { UserInscriptionFormValues } from '../../../../../interfaces/inscripcion';

// Services
import { InscripcionService } from '../../../../../services/inscripciones.service';

interface InscriptionFormProps {
  evento: Evento;
}
export const InscriptionForm: FC<InscriptionFormProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [openPreviouslyRegisteredModal, setOpenPreviouslyRegisteredModal] =
    useState<boolean>(false);

  return (
    <>
      <ErrorModal
        open={!!errorMessage}
        subtitle={errorMessage}
        onClose={() => setErrorMessage('')}
        title="Ocurrió un error al registrar tu inscripción"
      />

      <InscriptionConfirmationModal
        evento={props.evento}
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      />

      <PreviouslyRegisteredModal
        evento={props.evento}
        open={openPreviouslyRegisteredModal}
        onClose={() => setOpenPreviouslyRegisteredModal(false)}
      />

      <ProForm<UserInscriptionFormValues>
        requiredMark={false}
        className="inscription-form"
        style={{ textAlign: 'left' }}
        onFinish={async (values) => {
          try {
            values.participantes_cantidad = 1;
            await InscripcionService.registerToEvent(props.evento.id, values);
            setOpenConfirmationModal(true);
          } catch (error: any) {
            console.error('Error al realizar la inscripción', error.response.data);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message === 'La entidad ya se encuentra inscripta en este evento'
            ) {
              setOpenPreviouslyRegisteredModal(true);
            } else {
              setErrorMessage(
                error.response?.data?.message ||
                  'Ocurrió un error inesperada al registrar tu inscripción'
              );
            }
          }
        }}
        submitter={{
          render(props) {
            return (
              <Button
                {...props.submitButtonProps}
                type="primary"
                shape="round"
                style={{ width: '100%', height: 40 }}
                onClick={props.submit || props.onSubmit}
              >
                Confirmar inscripción
              </Button>
            );
          }
        }}
      >
        {/* <ProFormSelect
          fieldProps={{
            style: { borderRadius: 15 }
          }}
          showSearch
          name="participantes_cantidad"
          placeholder="Seleccione una cantidad"
          label="Cantidad de personas asistentes al evento"
          options={[
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 }
          ]}
          rules={[
            {
              required: true,
              message: 'Por favor, seleccione una cantidad de personas asistentes al evento'
            }
          ]}
        /> */}

        {/* <ProFormTextArea
          name="observaciones"
          fieldProps={{
            style: { borderRadius: 20 },
            autoSize: { minRows: 3 }
          }}
          label="Comentario u observación que le gustaría realizar"
          placeholder="Ingrese un comentario u observación opcional"
        ></ProFormTextArea> */}
      </ProForm>
    </>
  );
};
